import { Security } from 'common/security';
import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { Calendar } from 'services/calendar';
import { Nylas } from 'services/nylas';
import { Notifier } from 'common/ui';
import { c } from 'common/common';

export class BookingCalendarEditor {
    static inject = [Security, DialogController, NewInstance.of(ValidationController), Calendar, Nylas, Notifier];
    _security;
    dialogController;
    _calendar;
    _nylas;
    _notifier;

    daysAvailable = [];
    startAvailableHour;
    startAvailableMinute;
    endAvailableHour;
    endAvailableMinute;

    locationOptions = ['phone', 'online', 'other'];
    timezoneOptions = ['America/New_York', 'America/Chicago', 'America/Denver', 'America/Phoenix', 'America/Los_Angeles', 'America/Anchorage', 'Pacific/Honolulu', 'America/Adak'];
    durationMinuteOptions = ['15', '30', '45', '60', '90'];
    intervalMinuteOptions = ['15', '30'];
    bookingNoticeMinuteOptions = ['30', '60', '90', '120'];
    cancellationNoticeMinuteOptions = ['30', '60', '90', '120'];
    startEndMinuteOptions = ['00', '15', '30', '45'];
    dayOptions = [
        { code: 'sunday', selected: false },
        { code: 'monday', selected: false },
        { code: 'tuesday', selected: false },
        { code: 'wednesday', selected: false },
        { code: 'thursday', selected: false },
        { code: 'friday', selected: false },
        { code: 'saturday', selected: false }
    ];
    questions = [];
    questionTypes = ['text', 'multi_line_text', 'email', 'phone_number', 'dropdown', 'date', 'checkbox', 'radio_button'];

    constructor(security, dialogController, validationController, calendar, nylas, notifier) {
        this._security = security;
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._calendar = calendar;
        this._nylas = nylas;
        this._notifier = notifier;
    }

    activate(model) {
        model.bookingCalendar = model.bookingCalendar ?? {};
        this.bookingCalendar = JSON.parse(JSON.stringify(model.bookingCalendar));
        this.calendars = model.calendars ?? [];
        if (!this.bookingCalendar.id) {
            this.bookingCalendar.slug = `${this._security.agent.agentID}-${c.Helpers.uniqueId(7)}`;
            this.bookingCalendar.durationMinutes = '30';
            this.bookingCalendar.minutesBetweenBookings = '15';
            this.bookingCalendar.minimumMinutesBeforeBooking = '60';
            this.bookingCalendar.minimumMinutesBeforeCancellation = '60';
            this.dayOptions[1].selected = true;
            this.dayOptions[2].selected = true;
            this.dayOptions[3].selected = true;
            this.dayOptions[4].selected = true;
            this.dayOptions[5].selected = true;
            this.startAvailableHour = '8';
            this.startAvailableMinute = '0';
            this.endAvailableHour = '20';
            this.endAvailableMinute = '0';
            this.questions = [];
        } else {
            // set up the form with the current booking calendar model
            this.bookingCalendar.durationMinutes += '';
            this.bookingCalendar.minutesBetweenBookings += '';
            this.bookingCalendar.minimumMinutesBeforeBooking += '';
            this.bookingCalendar.minimumMinutesBeforeCancellation += '';
            this.dayOptions.forEach(o => o.selected = false);
            this.bookingCalendar.daysAvailable[0].days.forEach(day => {
                const doIndex = this.dayOptions.findIndex(x => x.code === day);
                if (doIndex >= 0) this.dayOptions[doIndex].selected = true;
            });
            this.startAvailableHour = `${this.bookingCalendar.daysAvailable[0].startHour}`;
            this.startAvailableMinute = `${this.bookingCalendar.daysAvailable[0].startMinute}`;
            this.endAvailableHour = `${this.bookingCalendar.daysAvailable[0].endHour}`;
            this.endAvailableMinute = `${this.bookingCalendar.daysAvailable[0].endMinute}`;
            this.questions = [];
            this.bookingCalendar.additionalFields.forEach(af => {
                this.questions.push({
                    question: af.name,
                    type: af.type,
                    isRequired: af.required,
                    answerOptions: af.options ?? [],
                });
            });
        }

        this._setupValidation();
    }

    _setupValidation() {
        ValidationRules
            .ensure('title').required()
            .ensure('location').required()
            .ensure('duration').required()
            .ensure('calendarId').required()
            .on(this.bookingCalendar);

        this.validationController.reset();
    }

    selectDay(day) {
        day.selected = !day.selected;
    }

    async save() {
        if (this.saving) return;
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;
            this.saving = true;

            this.bookingCalendar.daysAvailable = [];
            this.bookingCalendar.daysAvailable.push({
                days: this.dayOptions.filter(x => x.selected).map(x => x.code),
                startHour: Number(this.startAvailableHour),
                startMinute: Number(this.startAvailableMinute),
                endHour: Number(this.endAvailableHour),
                endMinute: Number(this.endAvailableMinute),
            });
            this.bookingCalendar.additionalFields = [];
            this.questions.forEach(q => {
                this.bookingCalendar.additionalFields.push({
                    name: q.question,
                    type: q.type,
                    required: q.isRequired,
                    options: q.answerOptions,
                });
            });

            await this._calendar.saveBookingCalendar(this.bookingCalendar);
            this.dialogController.ok();
        } catch (err) {
            console.log(err);
            this._notifier.generalError(err);
        } finally {
            this.saving = false;
        }
    }

    addQuestion(type = undefined) {
        this.questions.push({
            question: '',
            type: type ?? this.questionTypes[0],
            isRequired: false,
            referenceKey: '',
            answerOptions: [],
        });
    }

    removeQuestion(index) {
        this.questions.splice(index, 1);
    }

    checkQuestionForError(question) {
        question.hasError = !question.question ? true : false;
    }

    addAnswerOption(question) {
        if (!question.addOption) question.addOption = '';
        if (!question.answerOptions.includes(question.addOption)) {
            question.answerOptions.push(question.addOption);
        }
        question.addOption = '';
        question.focusAddOption = true;
    }

    removeAnswerOption(question, index) {
        question.answerOptions.splice(index, 1);
    }

    close() {
        this.dialogController.cancel();
    }
}
