import { Nylas } from 'services/nylas';
import { Router } from 'aurelia-router';

export class NylasCallback {
    static inject = [Nylas, Router];
    _nylas;
    _router;

    _notifyKey;
    _notifyType;
    _redirectTo;

    constructor(nylas, router) {
        this._nylas = nylas;
        this._router = router;
    }

    async activate(model) {
        try {
            console.log('nylas-callback', model);
            this._from = model.from;
            await this._nylas.authenticated(model.code, model.state);
            this._notifyKey = 'nylas-authentication-success';
            this._notifyType = 'success';
        } catch (err) {
            console.log(err);
            this._notifyKey = 'nylas-authentication-error';
            this._notifyType = 'error';
        }
    }

    attached() {
        let redirectTo = `#/members/my-profile?notifyKey=${this._notifyKey}&notifyType=${this._notifyType}`;
        if (this._from === 'task') {
            redirectTo = `#/members/tasks?notifyKey=${this._notifyKey}&notifyType=${this._notifyType}`;
        } else if (this._from === 'schedule-editor') {
            redirectTo = ``;
        }
        this._router.navigate(redirectTo);
    }
}